import Axios from '../api/http'
import { Message } from 'element-ui'

let permissions = []
let inited = false

const OperatorCache = {
  info: {},
  inited() {
    return inited
  },
  init(callBack) {
    let infoObj = sessionStorage.getItem('userInfo')
    if (inited) {
      callBack()
    } else if (infoObj && !this.info.Id) {
      this.info = JSON.parse(infoObj)
      callBack()
    } else {
      Axios.post('/Base_Manage/Home/GetOperatorInfo').then(resJson => {
        if (resJson.Success) {
          this.info = resJson.Data.UserInfo
          sessionStorage.setItem('userInfo', JSON.stringify(resJson.Data.UserInfo))
          permissions = resJson.Data.Permissions
          inited = true
        } else {
          Message.error('用户信息获取失败')
        }
        callBack()
      })
    }
  },
  hasPermission(thePermission) {
    return permissions.includes(thePermission)
  },
  updateInfo(callback) {
    Axios.post('/Base_Manage/Home/GetOperatorInfo').then(resJson => {
      if (resJson.Success) {
        this.info = resJson.Data.UserInfo
        sessionStorage.setItem('userInfo', JSON.stringify(resJson.Data.UserInfo))
        permissions = resJson.Data.Permissions
        inited = true
      } else {
        Message.error('用户信息获取失败')
      }
      callback()
    })
  },
  clear() {
    inited = false
    permissions = []
    this.info = {}
    sessionStorage.removeItem('userInfo')
  }
}

export default OperatorCache
