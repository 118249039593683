import axios from "axios";
import { Message } from "element-ui";
import router from "../router/index";
import global_ from "../config/global";
const service = axios.create({
  baseURL: global_.BASE_URL,
  // baseURL: 'http://192.168.1.111:5000',
  //设置超时时间，超过该时间就不会发起请求
  timeout: 3000000,
});
service.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    //？？？？？
    config.headers = {
      "Content-Type": "application/json", //配置请求头
    };
    config.headers["Authorization"] = "Bearer " + token; //配置token
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  //请求成功处理
  (response) => {
    const res = response;
    if (res.status === 200) {
      return res.data;
    } else {
      console.log(res);
    }
  },
  (error) => {
    console.log(error);
    if (JSON.stringify(error).includes("timeout")) {
      Message.error("用户信息失效,请重新登录");
      // router.replace('/Login')
    }
  }
);
export default service;
