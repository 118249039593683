<template>
  <!-- 面包屑导航组件 -->
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, i) in breadList" :key="i">
        <a
          style="cursor: pointer"
          @click.prevent="handleLink(item)"
          :class="{ active: $route.name === item.meta.title }"
        >
          {{ item.meta.title }}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      breadList: null,
    };
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    handleLink(item) {
      const { redirect, meta } = item;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(meta.path);
    },
    getBreadcrumb() {
      // 包含当前路由的所有嵌套路径片段的路由记录
      const matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      // 赋值循环渲染
      this.breadList = matched;
      console.log("路由信息");
    },
  },
  watch: {
    $route(route) {
      // 如果转到重定向页面，不要更新面包屑
      if (route.path.startsWith("/redirect/")) {
        return;
      }
      this.getBreadcrumb();
    },
  },
};
</script>
  <style scoped>
.active {
  color: #000 !important;
  font-weight: bold !important;
  font-size: 16px !important;
}
</style>
  