import Vue from 'vue'
import {
  Drawer,
  Row,
  Col,
  Tooltip,
  Button,
  Icon,
  Input,
  Card,
  Select,
  Popover,
  Modal,
  Popconfirm,
  InputNumber,
  Radio,
  FormModel,
  Upload,
  Tag,
  Checkbox
} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Drawer)
Vue.use(Row)
Vue.use(Tooltip)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Input)
Vue.use(Card)
Vue.use(Select)
Vue.use(Col)
Vue.use(Popover)
Vue.use(Modal)
Vue.use(Popconfirm)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(FormModel)
Vue.use(Upload)
Vue.use(Tag)
Vue.use(Checkbox)
